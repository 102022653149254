import styled from 'styled-components'

const CloseIconUi = styled.i`
  color: rgba(137, 149, 176, 0.7);
  margin-left: auto;
  font-size: 18px;
  padding: 5px 8px;
  cursor: pointer;
`

export default CloseIconUi
