import styled, { keyframes } from 'styled-components'

const bounceKeyframes = keyframes`
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.99);
  }
`

const OrderBumpArrowUi = styled.div`
  background-size: cover;
  min-height: 30px;
  min-width: 30px;
  margin: 0 10px;
  background-image: url(${process.env.EDITOR_ASSETS_HOST}/assets/images/payment/right-arrow.png);
  animation: ${bounceKeyframes} 0.2s infinite ease-in-out alternate;
  flex-basis: 30px;
`

export default OrderBumpArrowUi
