import React from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import CloseIconUi from 'common/components/entities/Comments/CommentEditorUi/CloseIconUi'
import CardFieldUi from 'common/components/entities/PaymentMethods/ui/CardFieldUi'
import CardLabelUi from 'common/components/entities/PaymentMethods/ui/CardLabelUi'
import CardRowUi from 'common/components/entities/PaymentMethods/ui/CardRowUi'
import FieldErrors from 'publisher/components/FieldErrors'
import { useXendit } from 'publisher/context/XenditContext'
import Overlay from 'publisher/pages/offer-page/components/Overlay'
import CreditCardInputUi from '../ui/CreditCardInputUi'
import CreditCardInputWrapperUi from '../ui/CreditCardInputWrapperUi'
import { getFormattedCardExpiry } from '../utils/paymentCreditCardUtils'
import { XenditIframeContainer, XenditIframeUi } from './ui/XenditIframeUi'

function XenditCreditCard() {
  const {
    fields,
    changeFields,
    errors,
    resetError,
    isSecureModalVisible,
    setIsSecureModalVisible,
  } = useXendit()
  const { t } = useTranslation(['common', 'publisher'])

  function changeCardNumber({ value }: NumberFormatValues) {
    changeFields.onChangeCardNumber(value)
    resetError('cardNumber', '')
  }

  function changeCardExpiry({ value }: NumberFormatValues) {
    changeFields.onChangeMonth(value.substring(0, 2))
    changeFields.onChangeYear('20' + value.substring(2, 4))
    resetError('expiryDate', '')
  }

  function handleCardholderNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = e.target.value.toUpperCase()
    changeFields.onChangeCardHolderName(e.target.value)
  }

  function handleCvv(e: React.ChangeEvent<HTMLInputElement>) {
    changeFields.onChangeCvv(e.target.value)
    resetError('cardCvn', '')
  }

  return (
    <>
      <CardRowUi>
        <CardFieldUi width="70%">
          <CardLabelUi htmlFor="mercado-card-number-mask">
            {t('components.payment_methods.card_number')}
          </CardLabelUi>
          <CreditCardInputWrapperUi hasErrors={Boolean(errors.cardNumber)}>
            <NumberFormat
              id="xendit-card-number-mask"
              format="#### #### #### ####"
              placeholder="1234 1234 1234 1234"
              customInput={CreditCardInputUi}
              onValueChange={changeCardNumber}
              value={fields.cardNumber}
            />
          </CreditCardInputWrapperUi>
          {errors.cardNumber && <FieldErrors errors={[errors.cardNumber]} />}
        </CardFieldUi>
        <CardFieldUi width="30%">
          <CardLabelUi htmlFor="xendit-card-expiration-month">
            {t('components.payment_methods.expiration_date')}
          </CardLabelUi>
          <div style={{ display: 'flex' }}>
            <CreditCardInputWrapperUi hasErrors={Boolean(errors.expiryDate)}>
              <NumberFormat
                format={getFormattedCardExpiry}
                placeholder="MM / YY"
                customInput={CreditCardInputUi}
                onValueChange={changeCardExpiry}
              />
            </CreditCardInputWrapperUi>
          </div>
          {errors.expiryDate && <FieldErrors errors={[errors.expiryDate]} />}
        </CardFieldUi>
      </CardRowUi>
      <CardRowUi>
        <CardFieldUi width="70%">
          <CardLabelUi htmlFor="xendit-card-holder-name">
            {t('components.payment_methods.card_holder_name')}
          </CardLabelUi>
          <CreditCardInputWrapperUi>
            <CreditCardInputUi
              type="text"
              id="xendit-card-holder-name"
              onChange={handleCardholderNameChange}
              value={fields.cardHolderName}
            />
          </CreditCardInputWrapperUi>
        </CardFieldUi>
        <CardFieldUi width="30%">
          <CardLabelUi htmlFor="xendit-card-security_code">
            {t('components.payment_methods.security_code')}
          </CardLabelUi>
          <CreditCardInputWrapperUi hasErrors={Boolean(errors.cardCvn)}>
            <CreditCardInputUi
              id="xendit-card-security_code"
              onChange={handleCvv}
              value={fields.cvv}
              maxLength={4}
            />
          </CreditCardInputWrapperUi>
          {errors.cardCvn && <FieldErrors errors={[errors.cardCvn]} />}
        </CardFieldUi>
      </CardRowUi>
      <Overlay
        open={isSecureModalVisible}
        onClose={() => setIsSecureModalVisible(false)}
        withCloseIcon={false}
      >
        <XenditIframeContainer>
          <div style={{ display: 'flex', width: '100%' }}>
            <CloseIconUi
              className="fa fa-times"
              onClick={() => setIsSecureModalVisible(false)}
            />
          </div>
          <XenditIframeUi></XenditIframeUi>
        </XenditIframeContainer>
      </Overlay>
    </>
  )
}

export default XenditCreditCard
