import styled from 'styled-components'
import { mobileTextCss, textCss } from 'common/utils/styleUtilsNew'

type WebinarSessionLinkUiNewProps = {
  color: string
  mobileColor?: string
  fontSize: number
  mobileFontSize?: number
}
const WebinarSessionLinkUiNew = styled.a<WebinarSessionLinkUiNewProps>`
  text-align: center;
  word-break: break-word;
  ${textCss}
  ${p => p.theme.isEditorMobileMode && mobileTextCss}
  ${p => p.theme.phone} {
    ${mobileTextCss}
  }
`

export default WebinarSessionLinkUiNew
