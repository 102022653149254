import React from 'react'
import WebinarSessionLinkUiNew from './ui/WebinarSessionLinkUiNew'

type WebinarSessionLinkNewProps = {
  redirectUrl?: string
  color: string
  mobileColor?: string
  fontSize: number
  mobileFontSize?: number
  htmlAttrId: string
  text?: string
}

const WebinarSessionLinkNew = ({
  htmlAttrId,
  redirectUrl,
  color,
  mobileColor,
  fontSize,
  mobileFontSize,
  text = '',
}: WebinarSessionLinkNewProps) => (
  <WebinarSessionLinkUiNew
    id={htmlAttrId}
    href={redirectUrl}
    color={color}
    mobileColor={mobileColor}
    fontSize={fontSize}
    mobileFontSize={mobileFontSize}
  >
    {text ? text : redirectUrl}
  </WebinarSessionLinkUiNew>
)

export default WebinarSessionLinkNew
